







import {
  SfButton, SfSection,
} from '@storefront-ui/vue';

import {
  computed, defineComponent, onMounted, ref,useFetch,
} from '@nuxtjs/composition-api';

import { useContent } from '~/composables';
import type { CmsBlock } from '~/modules/GraphQL/types';
export default defineComponent({
  name: 'HomeInfo',
  setup(props) {
		const {	loadBlocks } = useContent()
		const blockData = ref();
		onMounted(async () => {
			const res = await loadBlocks({ identifiers: ['malerifabrikken_designers'] });
			blockData.value = res[0] ? res[0].content : ''
		});

		return {
			blockData,
		};
  }
});
