var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home2" } },
    [
      _c("HomeBanner"),
      _vm._v(" "),
      _c("HomeTopCategory"),
      _vm._v(" "),
      _c(
        "LoadWhenVisible",
        [
          _c("NewProducts", {
            staticClass: "products",
            attrs: {
              "button-text": _vm.$t("See more"),
              title: _vm.$t("New Products"),
              link: "/women.html",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("HomeInfo"),
      _vm._v(" "),
      _c("HomeFooterTopCategory"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }