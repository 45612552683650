var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "home_info" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", {
        staticClass: "info_sec",
        domProps: { innerHTML: _vm._s(_vm.blockData) },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }