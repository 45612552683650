







import {
  SfButton, SfSection,
} from '@storefront-ui/vue';

import {
  computed, defineComponent, onMounted, ref,useFetch,
} from '@nuxtjs/composition-api';

import { useContent } from '~/composables';
import type { CmsBlock } from '~/modules/GraphQL/types';
export default defineComponent({
  name: 'HomeBanner',
  setup(props) {
	  const {
		loadBlocks,
	  } = useContent();
	   const blocks = ref();
	   const blockId = 'home_banner';
		useFetch(async () => { 
				const res = await loadBlocks({ identifiers: [blockId] });
				blocks.value = res[0] ? res[0].content : ''
		});	  
	  return {
		blocks
	  };
  }
});
